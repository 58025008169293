import * as Page from './[handle]'

export default Page.default

export const getStaticProps: (typeof Page)['getStaticProps'] = () =>
  Page.getStaticProps({
    params: {
      handle: 'home',
    },
  })
