/** @jsxImportSource theme-ui */
import { GetStaticPaths, GetStaticProps } from 'next'
import { memo } from 'react'
import SEO from 'src/components/SEO'
import { Box } from 'theme-ui'
import { PageHeaderProps, pageHeaderProps } from '~/api/header-settings'
import { queryContentful, withPageProps } from '~/api/services'
import ContentfulSections from '~/features/contentful-sections'
import { ContentfulSection } from '~/features/contentful-sections/types'

type NotRedirect<T> = T extends { notFound: true } ? never : T

type Props = NotRedirect<Awaited<ReturnType<typeof getStaticProps>>>['props']

function Page({ contentful }: Props) {
  let seoImage = contentful?.seoImage?.url
  seoImage =
    seoImage?.startsWith('http:') || seoImage?.startsWith('https:')
      ? seoImage
      : `https:${seoImage}`

  return (
    <Box
      sx={{
        backgroundColor: contentful?.backgroundColor?.colorArray,
        minHeight: contentful?.backgroundColor ? '100vh' : undefined,
      }}
    >
      <SEO
        title={contentful?.metaTitle ?? contentful?.title}
        description={contentful?.metaDescription}
        image={seoImage}
        type="website"
        key={`SEO-${contentful?.title}`}
      />
      <main>
        <ContentfulSections sections={contentful?.sections} />
      </main>
    </Box>
  )
}

export default memo(Page)

export const getPageData = async (handle: string) => {
  const data = await queryContentful<{
    pageCollection: {
      items: [
        {
          sys: { id: string }
          headerProps: PageHeaderProps
          title?: string
          handle: string
          metaTitle?: string
          metaDescription?: string
          seoImage?: {
            url: string
          }
          hidePage?: boolean
          backgroundColor?: {
            colorArray: string[]
          }
        },
      ]
    }
  }>(
    `query PageId($handle: String!) {
            pageCollection(limit: 1, where: {handle: $handle}) {
              items {
                title
                handle
                metaTitle
                metaDescription
                seoImage {
                  url
                }
                hidePage
                backgroundColor {
                  colorArray
                }
                sys { id }
                headerProps: header {
                  ${pageHeaderProps}
                }
              }
            }
          }`,
    {
      handle,
    }
  ).then((res) => res.data.pageCollection.items[0])

  return data
}

const getPageSections = async (pageId: string) => {
  const query = `#graphql
query Page($skip: Int!, $limit: Int!, $id: String!) {
  page(id: $id) {
    sectionsCollection(limit: $limit, skip: $skip) {
      items {
        __typename
        ... on Entry {
          sys {
            id
          }
        }
        ... on Quiz {
          title
         	discountCode
          discountText
          questions: questionsCollection(limit: 6) {
            items {
              questionTitle
              handle
              allowsMultipleAnswers
              weight
              answerOptions: answerOptionsCollection(limit: 10) {
                items {
                  answerText
                  answerHandle
                  products
                }
              }
              helpText
              helpDescription {
                json
              }
            }
          }
          headerBlock {
            sys {
              id
            }
          }
          successHeaderBlock {
            sys {
              id
            }
          }
        }
        ... on RoundedImage {
          alt
          image {
            url
            height
            width
          }
        }
        ... on IconFeatures {
          bottomButtonLink
          bottomButtonText
          featuresCollection {
            items {
              sys {
                id
              }
              icon {
                url
              }
              title
              titleColor {
                colorArray
              }
              description {
                json
              }
            }
          }
        }
        ... on CollectionProductGrid {
          isLabReportsSection
          labReportsCollection {
            items {
              productHandle
              imagesCollection {
                items {
                  url
                  height
                  width
                }
              }
            }
          }
          itemsPerRow
          itemsPerRowMobile
          itemsPerRowTablet
          title
          showTitle
          showDescription
          showReviewsCount
          maxHeight
          imageHeightPercentage
          productHandles
          productsCollection(limit: 20) {
            items {
              productHandle
              imagesCollection(limit: 2) {
                items {
                  url
                }
              }
            }
          }
        }
        ... on EmbedHtml {
          title
          html
        }
        ... on TitleSection {
          preTitle
          fontWeight
          headline
          headlineTag
          headlineFontSize
          textAlign
          subtitle {
            json
          }
        }
        ... on DropdownLinkList {
          title
          linksCollection(limit: 10) {
            items {
              text
              image {
                url
                height
                width
              }
              url
            }
          }
        }
        ... on RichPageContent {
          title
          content {
            json
          }
          padding
          maxWidth
          align: textAlign
        }
        ... on ImageNextToText {
          image {
            url
            height
            width
          }
          bulletPointsCollection {
            items {
              text
              icon {
                url
              }
            }
          }
          iconGroupCollection {
            items {
              title
              subTitle
              description {
                json
              }
              image {
                url
              }
            }
          }
          title
          titleTag
          preTitle
          underlineButton
          buttonUrl
          buttonText
          flipped
          description {
            json
          }
          backgroundColor {
            colorArray
          }
          textColor {
            colorArray
          }
        }
        ... on EmbedHtml {
          title
          html
        }
        ... on Faq {
          title
          questionsCollection {
            items {
              sys {
                id
              }
              question
              answer {
                json
              }
            }
          }
        }
        ... on SplitHeroImage {
          title
          titleTag
          herosubtitle: subtitle
          image {
            url
          }
          imageIsOnRight
          maxHeight
          minHeight
          contentColor {
            colorArray
          }
          contentBackgroundColor {
            colorArray
          }
        }
        ... on ImageWithTextOverlay { 
          secondButtonText: secondButtonTitle
          secondButtonUrl
          image {
            url
            height
            width
          }
          preTitle
          titleTag
          description {
            json
          }
          title
          titleSize
          titleLineHeight
          contentColor {
            colorArray
          }
          verticalPosition
          underlineButton
          contentPadding
          minHeight
          buttonText
          buttonUrl
          horizontalPosition
          underlineButton
          verticalPosition
          textAlign
          maxHeight
          imageFocusPosition
          contentMobilePosition
          contentMaxWidth
        }
        ... on PressLogoList {
          asSeenText
          backgroundColor {
            colorArray
          }
          linksCollection {
            items {
              quote
              publicationName
              image {
                url
                height
                width
              }
              url
            }
          }
        }
        ... on ContactForm {
          title
          successTitle
          successMessage
        }
        ... on InstagramSection {
          title
          instasubtitle: subtitle
          handle
          postsCollection {
            items {
              image {
                url
              }
              alt
              url
            }
          }
        }
        ... on SubscribeSection {
          subscribeText
          buttonText
          backgroundColor {
            colorArray
          }
          textColor {
            colorArray
          }
        }
        ... on TwoButtonTextSection {
          title
          description {
            json
          }
          leftText
          firstButtonImage {
            url
          }
          firstButtonText
          firstButtonUrl
          secondButtonImage {
            url
          }
          secondButtonText
          secondButtonUrl
        }
        ... on ContentSection {
          title
          description {
            json
          }
          horizontal
          itemsCollection{
            items {
              image {
                url
              }
              title
              subTitle
              description {
                json
              }
            }
          }
          showContentBorder
          backgroundColor {
            colorArray
          }
          textColor {
            colorArray
          }
          borderColor {
            colorArray
          }
        }
        ... on SmsSubscribeSection {
          title
          titleColor {
            colorArray
          }
          background {
            url
          }
          image {
            url
            height
            width
          }
        }
      }
    }
  }
}`

  const getSection = ({ limit, skip }: { limit: number; skip: number }) => {
    return queryContentful<{
      page: {
        sectionsCollection: {
          items: ContentfulSection[]
        }
      }
    }>(query, {
      id: pageId,
      limit,
      skip,
    })
  }

  const sections: ContentfulSection[] = []

  const limit = 1

  for (let i = 0; i < 20; i++) {
    const skip = i * limit

    const { data } = await getSection({ limit, skip })
    if (!data.page?.sectionsCollection?.items?.length) {
      break
    }

    sections.push(...data.page.sectionsCollection.items)
  }

  return sections
}

export const getStaticProps = (async ({ params }) => {
  const handle = params?.handle as string

  const data = await getPageData(handle)

  if (!data || data.hidePage) {
    return {
      notFound: true,
    }
  }

  return {
    props: await withPageProps({
      headerProps: data.headerProps,
      contentful: {
        ...data,
        sections: await getPageSections(data.sys.id),
      },
    }),
    revalidate: 60*60,
  }
}) satisfies GetStaticProps

export const getAllContentfulPages = (cache = false) =>
  queryContentful<{
    pageCollection: {
      items: [
        {
          handle: string
          hidePage: boolean
          sys: {
            publishedAt: string
          }
        },
      ]
    }
  }>(
    `query AllPages {
      pageCollection {
        items {
          handle
          hidePage
          sys {
            publishedAt
          }
        }
      }
    }`,
    {}
  )

export const getStaticPaths = (async () => {
  // if (process.env.NODE_ENV === 'development' || process.env.NO_STATIC_PATHS)
  //   return { paths: [], fallback: true }
  const allPages = await getAllContentfulPages()
  return {
    paths: allPages.data.pageCollection.items
      .filter((page) => !page.hidePage)
      .map((page) => ({ params: { handle: page.handle } })),
    fallback: true,
  }
}) satisfies GetStaticPaths
